import axios, { AxiosInstance } from 'axios'

const interceptorsAttach: (axiosInstance: AxiosInstance) => void = function (axiosInstance: AxiosInstance):void {
    axiosInstance.interceptors.request.use(function (config) {
        let token = localStorage.getItem('user-token')
        if (token) {
            config.headers['Authorization'] = token
        }
        return config
    }, function (error) {
        return Promise.reject(error)
    })

    axiosInstance.interceptors.response.use(function (response) {
        if (response.headers['x-auth-token']) {
            localStorage.setItem('user-token', response.headers['x-auth-token'])
        }
        return response
    }, function (error) {
        if (error.response.headers['x-auth-token']) {
            localStorage.setItem('user-token', error.response.headers['x-auth-token'])
        }
        return Promise.reject(error)
    })
}

interceptorsAttach(axios)

let getPage = function (type) {
    return import(`../frontend/infrastructure/components/${type}`)
}

let getPlugin = function (type) {
    return import(`../frontend/infrastructure/plugins/${type}.ts`)
}

export default { getPlugin, getPage }
